<template>
    <div class="container">
        <h1 class="mt-3">{{$t('basicData.heading')}}</h1>
        <p>{{$t('basicData.subtitle')}}</p>

        <div class="p-4 bg-white border-light rounded shadow">
            <basic-data-form
                :defaults="basicData || {}"
                :countries="countries"
                :risk-activities="riskActivities"
                :client-type="clientType"
                :date-picker-lang="datePickerLang"
                @submit="onSubmit"
                @cancel="onPrevious"
            />
        </div>

    </div>
</template>

<script>
import BasicDataForm from './Components/BasicDataForm.vue'
import ViewBase from '../ViewBase.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'BasicData',
    extends: ViewBase,
    components: {
        BasicDataForm
    },
    computed: {
        ...mapGetters([
            'riskActivities',
            'countries'
        ]),
        basicData () {
            return (this.sessionData && this.sessionData.basicData) ? this.sessionData.basicData : null
        }
    },
    methods: {
        onSubmit (basicData) {
            this.saveSessionData({ basicData: { ...basicData, clientType: this.sessionData.clientType } })
                .then(() => {
                    this.$store.commit('setDataHash', { name: 'basicDataOld', hash: null })
                    this.$store.commit('setDataHash', { name: 'basicData', hash: null })
                    this.$emit('next')
                })
        }
    }
}
</script>
